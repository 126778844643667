<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="content">
        <a-card title="次卡计费信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车辆类型">
                  <a-input v-model="form.kartClassifyName" :maxLength="50" allowClear :disabled="disabled"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="次卡类型">
                  <a-input v-model="form.timesCardName" :maxLength="50"  :disabled="disabled" ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="次数">
                  <a-input v-model="form.count" :maxLength="50" allowClear :disabled="disabled"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="次卡价格" prop="price">
                  <a-input-number
                    placeholder="请输入次卡价格"
                    v-model="form.price"
                    size="large"
                    :maxLength="10"
                    :min="0"
                    :max="9999999999"
                    allowClear
                  />
                  <span class="ml-20">
                        元
                      </span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="有效类型" prop="periodType">
                  <a-select
                    v-model="form.periodType"
                    allowClear
                  >
                    <a-select-option :value="0"> 长期 </a-select-option>
                    <a-select-option :value="1"> 自定义 </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="form.periodType === 1">
              <a-col :span="10">
                <a-form-model-item label="有效时间" prop="duration">
                  <a-range-picker
                    v-model="form.duration"
                    class="x-inner"
                    format="YYYY-MM-DD"
                  ></a-range-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="备注">
                  <a-input v-model="form.remarks" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" allowClear :maxLength="100" ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { timesCardRuleById, timesCardRuleSubmit } from '@/api/timesCard';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';
import { formatDate, toMoment } from '@/libs/utils';

export default {
  name: 'timesCardForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  computed: {
    disabled() {
      return this.$route.params.id !== undefined;
    },
  },
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  data() {
    return {
      form: {
        kartClassifyName: '',
        timesCardName: '',
        count: 0,
        price: 0,
        periodType: 0,
        duration: [],
        remarks: ''
      },
      rules: {
        price: [
          {
            required: true,
            message: '请正确输入价格',
          }
        ],
      },
    };
  },
  methods: {
    // 信息
    getData(id) {
      timesCardRuleById(id).then((data) => {
        data.duration = [];
        this.form = data;
        this.form.duration = [toMoment(data.startDate), toMoment(data.endDate)];
        console.log(this.form);
      });
    },
    // 保存
    handleSave() {
      const params = { ...this.form };
      if (params.duration && params.duration.length) {
        params.startDate = formatDate(params.duration[0]);
        params.endDate = formatDate(params.duration[1]);
      }
      delete params.duration;
      timesCardRuleSubmit(params).then(() => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
